import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-inbox-detail-modal',
  templateUrl: './inbox-detail-modal.component.html',
  styleUrls: ['./inbox-detail-modal.component.scss']
})
export class InboxDetailModalComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    
  }

  displayedColumns: string[] = ['tipo', 'solicitud', 'cliente', 'referencia', 'monto', 'banco'];
  dataSource = new MatTableDataSource<InboxDialog>();

  close() {
    this.dialog.closeAll();
  }
  
}


export interface InboxDialog {
  tipo: string;
  solicitud: number;
  cliente: string;
  referencia: number;
  monto: string;
  banco: string;
}