<div class="dashboard-container">

    <mat-toolbar class="toolbar ds-flex-between">
        <img src="../../../../../assets/img/logo-principal.svg">

        <div class="ds-flex-column-center">
            <div class="banner-img ds-flex-centers">
                <div class="font-20 text-white">Host to Host Afore</div>
            </div>
            <div class="ds-flex-align-center margin-05-2">
                <div class="font-22 margin-right-1 text-gray">¡Hola! {{name}}</div>
                <div class="font-22 font-600 initials ds-flex-centers text-white">{{initial}}</div>
            </div>
        </div>
    </mat-toolbar>

    <!-- Empieza sidenav-->
    <div>
        <mat-drawer-container class="sidenav-container">
            <mat-drawer class="sidenav" mode="side" opened>
                <mat-nav-list class="margin-t-b-1">
                    <!-- <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1" 
                    [routerLink]="nav.route" routerLinkActive="mat-nav-list-item-active"
                    *ngFor="let nav of navMenu">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-inbox.svg">
                                <div class="font-18">{{nav.name}}</div>
                            </div>
                            <div class="font-18 font-600">{{nav.count}}</div>
                        </div>
                    </a> -->

                    <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1" 
                    [routerLink]="['inbox']" routerLinkActive="mat-nav-list-item-active" #rla="routerLinkActive">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" [src]="rla.isActive ? 'assets/img/icon/fi-rr-inbox-active.svg' : 'assets/img/icon/fi-rr-inbox.svg'">
                                <div class="font-18">Bandeja de entrada</div>
                            </div>
                            <div class="font-18 font-600">{{countEntrada}}</div>
                        </div>
                    </a>

                    <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1" 
                    [routerLink]="['review']" routerLinkActive="mat-nav-list-item-active" #rla2="routerLinkActive">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" [src]="rla2.isActive ? 'assets/img/icon/fi-rr-list-check-active.svg' : 'assets/img/icon/fi-rr-list-check.svg'">
                                <div class="font-18">En revisión</div>
                            </div>
                            <div class="font-18 font-600">{{countReview}}</div>
                        </div>
                    </a>

                    <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1" 
                    [routerLink]="['response']" routerLinkActive="mat-nav-list-item-active" #rla3="routerLinkActive">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" [src]="rla3.isActive ? 'assets/img/icon/fi-rr-comment-alt-active.svg' : '../../../../../assets/img/icon/fi-rr-comment.svg'">
                                <div class="font-18">Respuesta</div>
                            </div>
                            <div class="font-18 font-600">{{countEnviado}}</div>
                        </div>
                    </a>

                    <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1" 
                    [routerLink]="['statistics']" routerLinkActive="mat-nav-list-item-active" #rla4="routerLinkActive">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" [src]="rla4.isActive ? 'assets/img/icon/fi-rr-chart-pie-alt-active.svg' : 'assets/img/icon/fi-rr-chart-pie.svg'">
                                <div class="font-18">Estadísticas</div>
                            </div>
                            <div class="font-18 font-600"></div>
                        </div>
                    </a>

                    <a class="mat-nav-list-item ds-flex margin-bottom-1 padding-t-b-1 margin-top-4"
                    routerLinkActive="mat-nav-list-item-active" #rla5="routerLinkActive" (click)="logoutConfirmation()">
                        <div class="ds-flex-center-between w-100">
                            <div class="ds-flex-align-center">
                                <img class="margin-right-05" [src]="rla5.isActive ? 'assets/img/icon/fi-rr-undo-active.svg' : 'assets/img/icon/fi-rr-undo.svg'">
                                <div class="font-18">Cerrar sesión</div>
                            </div>
                        </div>
                    </a>
                </mat-nav-list>

            </mat-drawer>

            <mat-drawer-content class="sidenav-content">
                <router-outlet></router-outlet>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>