<div [hidden]="!isLoading" class="loader-wrapper">
    <div class="spinner">  
    </div>
</div>
<div class="ds-flex-between-align-center banner-filter" [formGroup]="statisticsForm">
    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Rango fecha:</mat-label>
        <mat-form-field appearance="fill">
            <div class="ds-flex-center-between">
                <mat-date-range-input [rangePicker]="picker" [dateFilter]="myFilter">
                    <input matStartDate formControlName="start"  placeholder="DD-M-MYY">
                    <input matEndDate formControlName="end" placeholder="DD-MM-YY" >
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
        </mat-form-field>
    </div>
    <button class="button-search" (click)="search()">
        <img src="../../../../../assets/img/icon/fi-rr-search-white.svg">
    </button>
</div>

<div class="container-component" [hidden]="isLoading">
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label class="primary ds-flex-align-center">
                <div class="ds-flex-align-end">
                    <mat-icon class="margin-right-05">
                        <img src="../../../../../assets/img/icon/fi-rr-bank.svg">
                    </mat-icon>
                    <div class="font-16 text-gray">Sucursal</div>
                </div>
            </ng-template>

            <div class="font-20 font-w-400 text-gray margin-top-1 margin-bottom-1">
                <span>Periodo: </span>
                <span>{{fechaInicio}}</span>
                <span> - </span>
                <span>{{fechaFin}}</span>
            </div>

            <section class="overflow-x" tabindex="0">
                <table mat-table [dataSource]="dataSucursal" class="table-content">
                    <ng-container *ngFor="let columnName of columnNames" [matColumnDef]="columnName">
                        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
                        <td mat-cell *matCellDef="let row">{{ row[columnName] }}</td>
                      </ng-container>      
                      <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
                </table>
                <mat-paginator #MatPaginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </section>
            <div class="ds-flex-between">
                <div class="ds-flex-end margin-top-1">
                    <button class="button-blue-download ds-flex-centers" (click)="exportSucursal()">
                        <img class="margin-right-04" src="../../../../../assets/img/icon/fi-rr-grid-alt.svg">
                        <div>Exportar .xls</div>
                    </button>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div class="ds-flex-align-end" >
                    <mat-icon class="margin-right-05">
                        <img src="../../../../../assets/img/icon/fi-rr-archive.svg">
                    </mat-icon>
                    <div class="font-16 text-gray">Tipo de Retiro</div>
                </div>

            </ng-template>

            <div class="font-20 font-w-400 text-gray margin-top-1 margin-bottom-1">
                <span>Periodo: </span>
                <span>{{fechaInicio}}</span>
                <span> - </span>
                <span>{{fechaFin}}</span>
            </div>

            <section class="overflow-x">
                <table mat-table [dataSource]="dataRetiro" class="table-content">
                    <ng-container *ngFor="let columnName of columnNamesRetiro" [matColumnDef]="columnName">
                        <th mat-header-cell *matHeaderCellDef>{{ columnName }}</th>
                        <td mat-cell *matCellDef="let row">{{ row[columnName] }}</td>
                      </ng-container>      
                      <tr mat-header-row *matHeaderRowDef="columnNamesRetiro"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnNamesRetiro"></tr>
                </table>
                <mat-paginator #MatPaginator2 [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </section>
            <div class="ds-flex-between">
                <div class="ds-flex-end margin-top-1">
                    <button class="button-blue-download ds-flex-centers" (click)="exportTr()">
                        <img class="margin-right-04" src="../../../../../assets/img/icon/fi-rr-grid-alt.svg">
                        <div>Exportar .xls</div>
                    </button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>