import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Bandeja } from '../../inbox/components/inbox/inbox.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  error: string = "";
  constructor(
    public dialog: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) {
      this.error = data.error;
    }
    ngOnInit(): void {
    }

    close(){
      this.dialog.close();
    }

}
