import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent, dbConfig, routes } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule } from '@angular/material/core';
import { LoginComponent } from './modules/login/components/login/login.component';
import { InboxComponent } from './modules/inbox/components/inbox/inbox.component';
import { MatSelectModule } from '@angular/material/select';
import { getConfigPaginator } from './services/init.services';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import {MatSidenavModule} from '@angular/material/sidenav';
import { SidenavComponent } from './modules/shared/components/sidenav/sidenav.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { ReviewComponent } from './modules/review/components/review/review.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import { ResponseComponent } from './modules/response/components/response/response.component';
import { StatisticsComponent } from './modules/statistics/components/statistics/statistics.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NotificationComponent } from './modules/response/components/notification/notification.component';
import { NotificationResponseComponent } from './modules/response/components/modals/notification-response/notification-response.component';
import { DownloadResponseComponent } from './modules/response/components/modals/download-response/download-response.component';
import { InboxDetailModalComponent } from './modules/inbox/components/inbox-detail-modal/inbox-detail-modal.component';
import { ReviewDetailComponent } from './modules/review/components/review-detail/review-detail.component';
import { DuplicateRequestComponent } from './modules/review/components/modals/duplicate-request/duplicate-request.component';
import { LogoutConfirmationComponent } from './modules/logout/components/logout-confirmation/logout-confirmation.component';
import { Amplify } from 'aws-amplify';
import { ConfirmRevisionComponent } from './modules/modals/confirm-revision/confirm-revision.component';
import { ErrorComponent } from './modules/modals/error/error.component';
import { DeclineComponent } from './modules/review/components/decline/decline.component';
import { AlertReviewComponent } from './modules/review/components/modals/alert-review/alert-review.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { ResponseDetailComponent } from './modules/response/components/response-detail/response-detail.component';
import { ConfirmComponent } from './modules/modals/confirm/confirm.component';
import localeMx from '@angular/common/locales/es-MX';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { environment } from 'src/environments/environment';
registerLocaleData(localeMx)

Amplify.configure({
  Auth: {
    //region:'us-east-1',
    region:environment.amplifyConfig.Auth.region,
    //userPoolId: 'us-east-1_O22COVhug',
    userPoolId: environment.amplifyConfig.Auth.userPoolId,
    //userPoolWebClientId: '1h1qbhmkv8tbpmv1dq1afkno9o',
    userPoolWebClientId: environment.amplifyConfig.Auth.userPoolWebClientId,
    limit: 50,
    oauth: {
      //domain: 'hth-user-pool-develop.auth.us-east-1.amazoncognito.com',
      domain: environment.amplifyConfig.Auth.oauth.domain,
      scope: ['phone', 'email', 'openid', 'profile'],
      redirectSignIn: environment.amplifyConfig.Auth.oauth.redirectSignIn,
      redirectSignOut: environment.amplifyConfig.Auth.oauth.redirectSignOut,
      responseType: 'code'
    },
  },
  Analytics:{
    disabled:true
  }
})

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InboxComponent,
    SidenavComponent,
    ReviewComponent,
    ResponseComponent,
    StatisticsComponent,
    NotificationComponent,
    NotificationResponseComponent,
    DownloadResponseComponent,
    InboxDetailModalComponent,
    ReviewDetailComponent,
    DuplicateRequestComponent,
    LogoutConfirmationComponent,
    ConfirmRevisionComponent,
    ErrorComponent,
    DeclineComponent,
    AlertReviewComponent,
    ResponseDetailComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTabsModule ,
    MatDialogModule,
    MatDatepickerModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    {provide: MatPaginatorIntl, useValue: getConfigPaginator()},
    {provide:LOCALE_ID, useValue: 'es-MX'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi:true}
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
