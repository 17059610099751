import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Bandeja, Catalogo, CrearArchivo, DetalleBandeja, ParamsConsult, Stage } from 'src/app/modules/inbox/components/inbox/inbox.component';
import { ConsumeService } from 'src/app/services/consume.services';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';
import { ErrorComponent } from 'src/app/modules/modals/error/error.component';
import { ConfirmRevisionComponent } from 'src/app/modules/modals/confirm-revision/confirm-revision.component';
import { DataBaseService } from 'src/app/services/database.service';
import { Observable } from 'rxjs';
import { ModifyCountEmmiter } from 'src/app/modules/inbox/components/inbox/modify-count-emmiter';
import { ConfirmComponent } from 'src/app/modules/modals/confirm/confirm.component';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit, AfterViewInit {

  retiros = new FormControl('');
  retiroList: Catalogo[] = [];
  depositos = new FormControl('');
  depositosList: Catalogo[] = [];
  cuentaRetiro = new FormControl('');
  cuentaRetiroList: Catalogo[] = [];
  selectedTabIndex = 0;
  bandejaFull: Bandeja[] = [];
  bandeja02: Bandeja[] = [];
  bandeja12: Bandeja[] = [];
  bandeja22: Bandeja[] = [];
  bandejaRech: Bandeja[] = [];
  detallePeticion : DetalleBandeja = {
    Tot_Regs: '',
    Total_Opr: '',
    Total_SPEI: '',
    Total_7699: '',
    Total_8004: '',
    Tot_General: ''
  }; 
  searchForm = this.fb.group({
    nameFilter: ['']
  });

  //Para filtros
  bF: String[] = [];
  bFtipoPago: String[] = [];
  bFcuentaRetiro: String[] = [];
  total: Bandeja[] = [];
  dataSourceBackUp: Bandeja[] = [];
  dataSourceErringBackUp: Bandeja[] = [];
  dataSource12BackUp: Bandeja[] = [];
  dataSource22BackUp: Bandeja[] = [];
  dataSourceRechBackUp: Bandeja[] = [];
  totalFinal: Bandeja[] = [];
  index:Number=0;

  //Paginadores
  @ViewChild('MatPaginator') MatPaginator : MatPaginator;
  @ViewChild('MatPaginator2') MatPaginator2 : MatPaginator;
  @ViewChild('MatPaginator3') MatPaginator3 : MatPaginator;
  @ViewChild('MatPaginator4') MatPaginator4 : MatPaginator;
  @ViewChild('MatPaginator5') MatPaginator5 : MatPaginator;
  @ViewChildren('myCheckbox') myCheckbox : QueryList<any>;

  //botones a mostrat
  showRech: boolean = false;
  showAprob: boolean = false;
  showEnv: boolean = false;
  isLoading: boolean = true;

  respuestaArchivosS3: ResponseArchivoBanamex[] = [];
  tabIndexSet: Number = 0;

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private consumeService: ConsumeService,
    private router: Router,
    private fb: FormBuilder, public dataBase:DataBaseService, public totalEmmit:ModifyCountEmmiter,
    private utils: UtilsService
  ) {
    if(localStorage.getItem('tabIndexSet')=== undefined ||localStorage.getItem('tabIndexSet')=== null){
      localStorage.setItem('tabIndexSet', '0');
    }
    this.loadBackMenu();
  }

  ngAfterViewInit(): void {
    this.dataSourceAll.paginator = this.MatPaginator;
    this.dataSourceErrings.paginator = this.MatPaginator2;
    this.dataSource12.paginator = this.MatPaginator3;
    this.dataSourceApproved.paginator = this.MatPaginator4;
    this.dataSourceRech.paginator = this.MatPaginator5;
}

  invokeCatalog(){
    this.dataBase.obtenerCatalogos()
    .subscribe( data => {
      if(data!== undefined){
        console.log('contenido catalogo ',data);
        this.retiroList = data.value.CatalogoRetiros;
        this.depositosList = data.value.CatalogoTiposPago;
        this.cuentaRetiroList = data.value.CatalogoCuentasRetiro;
      }else{
        console.log('Redirecciona a portal login....');
      }
    }, err => console.log(err) );
  }

  ngOnInit(): void {
    this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
    if(localStorage.getItem('tabIndexSet')=== undefined || localStorage.getItem('tabIndexSet')=== null){
      this.tabIndexSet= 0;
    }else{
      this.tabIndexSet = Number(localStorage.getItem('tabIndexSet'));
    }
    
    this.isLoading = true;
    this.selection.clear();
    this.invokeCatalog();
    let busqueda = this.search('ER');
    this.consumeService.obtenerPagos(busqueda)
    .subscribe( data => {
      console.log("token: "+data.ResumenResponses);
      this.detallePeticion.Tot_Regs = data.Tot_Regs;
      this.detallePeticion.Total_Opr = data.Total_Opr;
      this.detallePeticion.Total_SPEI = data.Total_SPEI;
      this.detallePeticion.Total_7699 = data.Total_7699;
      this.detallePeticion.Total_8004 = data.Total_8004;
      this.detallePeticion.Tot_General = data.Tot_General;

      this.bandejaFull = this.generateData(data.ResumenResponses);
      this.dataSourceBackUp = this.bandejaFull;
      //this.dataSourceBackUp = this.bandejaFull;
      //this.dataSourceAll = new MatTableDataSource<Bandeja>(this.bandejaFull);

      //this.bandejaFull = this.generateData('');
    this.dataSourceAll = new MatTableDataSource<Bandeja>(this.bandejaFull);

    this.bandeja02 = this.bandejaFull;
    this.bandeja02 = this.bandeja02.filter((item) => 
      (item.Aprobador_1 === null || item.Aprobador_1 === undefined) && item.Status_Op !== 'RECHAZADO'
    );
    this.dataSourceErrings = new MatTableDataSource<Bandeja>(this.bandeja02);
    this.dataSourceErringBackUp = this.bandeja02;

    this.bandeja12 = this.bandejaFull;
    this.bandeja12 = this.bandeja12.filter((item) => 
      item.Aprobador_2 === null && item.Aprobador_1 !== null && item.Status_Op !== 'RECHAZADO'
    );
    this.dataSource12 = new MatTableDataSource<Bandeja>(this.bandeja12);
    this.dataSource12BackUp = this.bandeja12;

    this.bandeja22 = this.bandejaFull;
    this.bandeja22 = this.bandeja22.filter((item) => 
      item.Aprobador_2 !== null && item.Aprobador_2 !== undefined
    );
    this.dataSourceApproved = new MatTableDataSource<Bandeja>(this.bandeja22);
    this.dataSource22BackUp = this.bandeja22;

    this.bandejaRech = this.bandejaFull;
    this.bandejaRech = this.bandejaRech.filter((item) => 
      //item.Observaciones !== null && item.Observaciones !== undefined    ---RECHAZADO
      item.Status_Op === 'RECHAZADO'
    );
    
    this.dataSourceRech = new MatTableDataSource<Bandeja>(this.bandejaRech);
    this.dataSourceRechBackUp = this.bandejaRech;
    this.isLoading = false;
    this.dataSourceAll.paginator = this.MatPaginator;
    this.dataSourceErrings.paginator = this.MatPaginator2;
    this.dataSource12.paginator = this.MatPaginator3;
    this.dataSourceApproved.paginator = this.MatPaginator4;
    this.dataSourceRech.paginator = this.MatPaginator5;
    
    }, err => {
      console.log(err);
      this.isLoading = false;
     } );

    
  }

  displayedColumns: string[] = ['select', 'tipo', 'cuenta', 'tipoRetiro', 'clave', 'operaciones', 'monto', 'estatus', 'button'];
  selection = new SelectionModel<Bandeja>(true, []);
  dataSourceAll = new MatTableDataSource<Bandeja>();
  dataSourceErrings = new MatTableDataSource<Bandeja>();
  dataSourceApproved = new MatTableDataSource<Bandeja>();
  dataSource12 = new MatTableDataSource<Bandeja>();
  dataSourceRech = new MatTableDataSource<Bandeja>();

  onTabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.index = tabChangeEvent.index;
    console.log('tabChangeEvent => ', tabChangeEvent); 
    console.log('index => ', tabChangeEvent.index);
    localStorage.setItem('tabIndexSet', tabChangeEvent.index.toString());

    this.selection.clear();
    //this.selection = new SelectionModel<Bandeja>(true, []);
    this.searchForm.controls.nameFilter.setValue('');

    if(this.index === 0){
        this.showRech = false;
        this.showAprob = false;
        this.showEnv = false;
        this.dataSourceAll = new MatTableDataSource<Bandeja>(this.dataSourceBackUp);
        this.dataSourceErrings = new MatTableDataSource<Bandeja>();
        this.dataSource12 = new MatTableDataSource<Bandeja>();
        this.dataSourceApproved = new MatTableDataSource<Bandeja>();
        this.dataSourceRech = new MatTableDataSource<Bandeja>();
    }else if(this.index === 1){
        this.showRech = true;
        this.showAprob = true;
        this.showEnv = false;
        this.dataSourceAll = new MatTableDataSource<Bandeja>();
        this.dataSourceErrings = new MatTableDataSource<Bandeja>(this.dataSourceErringBackUp);
        this.dataSource12 = new MatTableDataSource<Bandeja>();
        this.dataSourceApproved = new MatTableDataSource<Bandeja>();
        this.dataSourceRech = new MatTableDataSource<Bandeja>();
    }else if(this.index === 2){
        this.showRech = true;
        this.showAprob = true;
        this.showEnv = false;
        this.dataSourceAll = new MatTableDataSource<Bandeja>();
        this.dataSourceErrings = new MatTableDataSource<Bandeja>();
        this.dataSource12 = new MatTableDataSource<Bandeja>(this.dataSource12BackUp);
        this.dataSourceApproved = new MatTableDataSource<Bandeja>();
        this.dataSourceRech = new MatTableDataSource<Bandeja>();
    }else if(this.index === 3){
        this.showRech = false;
        this.showAprob = false;
        this.showEnv = true;
        this.dataSourceAll = new MatTableDataSource<Bandeja>();
        this.dataSourceErrings = new MatTableDataSource<Bandeja>();
        this.dataSource12 = new MatTableDataSource<Bandeja>();
        this.dataSourceApproved = new MatTableDataSource<Bandeja>(this.dataSource22BackUp);
        this.dataSourceRech = new MatTableDataSource<Bandeja>();
    }else if(this.index === 4){
        this.showRech = false;
        this.showAprob = false;
        this.showEnv = false;
        this.dataSourceAll = new MatTableDataSource<Bandeja>();
        this.dataSourceErrings = new MatTableDataSource<Bandeja>();
        this.dataSource12 = new MatTableDataSource<Bandeja>();
        this.dataSourceApproved = new MatTableDataSource<Bandeja>();
        this.dataSourceRech = new MatTableDataSource<Bandeja>(this.dataSourceRechBackUp);
    }
    this.retiroList =[];
    this.depositosList = [];
    this.cuentaRetiroList = [];
    this.invokeCatalog();
    this.dataSourceAll.paginator = this.MatPaginator;
    this.dataSourceErrings.paginator = this.MatPaginator2;
    this.dataSource12.paginator = this.MatPaginator3;
    this.dataSourceApproved.paginator = this.MatPaginator4;
    this.dataSourceRech.paginator = this.MatPaginator5;
    
    //this.selection.clear();
    //this.myCheckbox.toArray();
    //this.myCheckbox[0].checked = false;
    
    
  }

  generateData(data: any): Bandeja[] {
    const x: Bandeja[] = [];
    data.forEach((e: any) => {
      x.push({
        Tipo_Pago: e.Tipo_Pago,
        Cuenta_Retiro: e.Cuenta_Retiro,
        Clave: e.Clave,
        Tipo_Retiro: e.Tipo_Retiro,
        Monto_Total: e.Monto_Total,
        Num_Operaciones: e.Num_Operaciones,
        Id_Proceso: e.Id_Proceso,
        Fecha: e.Fecha,
        Status_Op: e.Status_Op,
        Aprobador_1: e.Aprobador_1,
        Aprobador1_Nombre: e.Aprobador1_Nombre,
        Aprobador_2: e.Aprobador_2,
        Aprobador2_Nombre: e.Aprobador2_Nombre,
        Observaciones: e.observaciones,
        Op_Aprobadas: e.Op_Aprobadas,
        Monto_Operaciones_Aplicadas: e.Monto_Operaciones_Aplicadas,
        Op_Rechazadas: e.Op_Rechazadas,
        Monto_Operaciones_Rechazadas: e.Monto_Operaciones_Rechazadas,
        XML_Status_Envio: ''
      });
    });
    return x;
  }

  showDetail(renglon:any): void{
    console.log('Mandando a detalle'+renglon);
    let etepaProceso = '';
    if((renglon.Aprobador1_Nombre === null || renglon.Aprobador1_Nombre === undefined) && renglon.Status_Op !== 'RECHAZADO'){ 
      etepaProceso = '0/2';
    }else if(renglon.Aprobador1_Nombre !== null && renglon.Aprobador1_Nombre !== undefined &&
      (renglon.Aprobador2_Nombre === null || renglon.Aprobador2_Nombre === undefined) && renglon.Status_Op !== 'RECHAZADO'){
      etepaProceso = '1/2';
    }else if(renglon.Aprobador1_Nombre !== null && renglon.Aprobador1_Nombre !== undefined &&
      renglon.Aprobador2_Nombre !== null && renglon.Aprobador2_Nombre !== undefined){
      etepaProceso = 'Aprobado';
    }else if(renglon.Status_Op === 'RECHAZADO'){
      etepaProceso = 'Rechazado';
    }
    this.router.navigate(['review/detail'], { state: { hello: renglon, status:'ER', etapa:etepaProceso } });
  }

  getValuesCustom(){
    let valor : any; 
    this.getValues(valor,'');
  }

  getValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }, tipo: String) {
  
    let bandejaFullTmp;
    if(this.index === 0){
        bandejaFullTmp = this.dataSourceBackUp;
    }else if(this.index === 1){
        bandejaFullTmp = this.dataSourceErringBackUp;
    }else if(this.index === 2){
        bandejaFullTmp = this.dataSource12BackUp;
    }else if(this.index === 3){
        bandejaFullTmp = this.dataSource22BackUp;
    }else if(this.index === 4){
        bandejaFullTmp = this.dataSourceRechBackUp;
    }
        

    
    this.total = [];
    //}
    
    if(tipo === 'retiro'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bF.push(event.source.value.Id);
        } else {
          const index: number = this.bF.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bF.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'deposito'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFtipoPago.push(event.source.value.Desc);
        } else {
          const index: number = this.bFtipoPago.indexOf(event.source.value.Desc);
          if (index !== -1) {
              this.bFtipoPago.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'cuenta'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFcuentaRetiro.push(event.source.value.Id);
        } else {
          const index: number = this.bFcuentaRetiro.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bFcuentaRetiro.splice(index, 1);
          }   
        }
      }
    }

    bandejaFullTmp.forEach((item) => {
      this.bFtipoPago.forEach(e => {
        let cantTipoPago = [];
        if (item.Tipo_Pago === e) {
          cantTipoPago = this.total.filter(
            (interno) => interno.Tipo_Pago == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantTipoPago.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bF.forEach(e => {
        let cantBf = [];
        if (item.Id_Proceso === e) {
          cantBf = this.total.filter(
            (interno) => interno.Id_Proceso == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantBf.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bFcuentaRetiro.forEach(e => {
        let cantCuenRet = [];
        if (item.Cuenta_Retiro === e) {
          cantCuenRet = this.total.filter(
            (interno) => interno.Cuenta_Retiro == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantCuenRet.length <= 0){
            this.total.push(item);
          }
        } 
      });
    });
    
    this.totalFinal = [];
    this.total.forEach(val => this.totalFinal.push(Object.assign({}, val)));


    if(this.total.length > 0 && this.bFtipoPago.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFtipoPago.forEach(e => {
          if (item.Tipo_Pago === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Tipo_Pago).indexOf(item.Tipo_Pago);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bF.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bF.forEach(e => {
          if (item.Id_Proceso === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Id_Proceso).indexOf(item.Id_Proceso);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bFcuentaRetiro.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFcuentaRetiro.forEach(e => {
          if (item.Cuenta_Retiro === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Cuenta_Retiro).indexOf(item.Cuenta_Retiro);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.bF.length === 0 && this.bFcuentaRetiro.length === 0 && this.bFtipoPago.length === 0){
      //this.totalFinal = this.dataSourceBackUp;

        if(this.index === 0){
            this.totalFinal = this.dataSourceBackUp;
        }else if(this.index === 1){
            this.totalFinal = this.dataSourceErringBackUp;
        }else if(this.index === 2){
            this.totalFinal = this.dataSource12BackUp;
        }else if(this.index === 3){
            this.totalFinal = this.dataSource22BackUp;
        }else if(this.index === 4){
            this.totalFinal = this.dataSourceRechBackUp;
        }
    }
    
    //Validar monto o clave
    if(this.searchForm.controls.nameFilter.value !== ''){
      this.totalFinal = this.totalFinal.filter((item) => 
        item.Clave === this.searchForm.controls.nameFilter.value || item.Monto_Total.toString() === this.searchForm.controls.nameFilter.value
    );
    }

    if(this.index === 0){
        this.dataSourceAll = new MatTableDataSource<Bandeja>(this.totalFinal);
        this.dataSourceAll.paginator = this.MatPaginator;
    }else if(this.index === 1){
        this.dataSourceErrings = new MatTableDataSource<Bandeja>(this.totalFinal);
        this.dataSourceErrings.paginator = this.MatPaginator2;
    }else if(this.index === 2){
        this.dataSource12 = new MatTableDataSource<Bandeja>(this.totalFinal);
        this.dataSource12.paginator = this.MatPaginator3;
    }else if(this.index === 3){
        this.dataSourceApproved = new MatTableDataSource<Bandeja>(this.totalFinal);
        this.dataSourceApproved.paginator = this.MatPaginator4;
    }else if(this.index === 4){
        this.dataSourceRech = new MatTableDataSource<Bandeja>(this.totalFinal);
        this.dataSourceRech.paginator = this.MatPaginator5;
    }
   
    this.bandejaFull = this.totalFinal;
  }

  rechazarPago(){

    var elements : Bandeja[] = this.selection.selected;

    if(elements.length <= 0){
      this.dialog.open(ErrorComponent,{data:{error:"Seleccione al menos un registro a rechazar."}});
    }else{

      this.router.navigate(['review/decline'], { state: { hello: elements, tipoStatus:'ER', status:'RECH' } });
/*
      const dialogRef = this.dialog.open(ConfirmRevisionComponent,{data:{pagos:this.selection.selected}});

      dialogRef.afterClosed().subscribe(result =>{
        console.log('detalle total '+result);
        if(result){
          console.log('Se aceptan pagos');
          const x: Stage[] = [];
          elements.forEach( element =>{
            console.log(element.Clave);
            x.push({
              fecha: '2009-04-02',//fecha: element.Fecha,
              claveRetiro: element.Clave,
              tipoStatus: 'AR',
              status: 'RECH',
              observaciones: '',
              usuario: '',
              usuarioNm: ''
            });
            this.consumeService.actualizarEstatusPago(x).subscribe( data => {
              console.log("updateStage: "+data);
              //TODO: Invocar a refrescar pantalla
            }, err => console.log(err) );
          });
        }else{
          console.log('Se rechazan pagos');
        }
      });*/
    

    }   
  }

  validarCambioEstatus(elements : Bandeja[]):Observable<Custom>{
    let valido = true;
    let custom : Custom = {
      valido: false,
      usuario: ''
    };
    return new Observable<any>((observer) => {
        this.dataBase.obtenerUsuario()
        .subscribe( data => {
        if(data!== undefined){
          console.log('Id de usuario ',data);
          elements.forEach(e => {
            if(e.Aprobador1_Nombre === data || e.Aprobador2_Nombre === data){
              valido = false;
            }
          });
          custom.valido=valido;
          custom.usuario=data;
          observer.next(custom);
        }else{
          console.log('Redirecciona a portal login....');
        }
      }, err => console.log(err) );
    });
  }

  validarRechazo(elements : Bandeja[]):boolean{
    let valido = true;
    elements.forEach(e => {
      if(e.Observaciones !== undefined && e.Observaciones !== '' && e.Observaciones !== null){
        valido = false;
      }
    });
    return valido;
  }
  
  aprobarPago(){
    var elements : Bandeja[] = this.selection.selected;
    if(elements.length <= 0){
      this.dialog.open(ErrorComponent,{data:{error:"Seleccione al menos un registro a aprobar."}});
    }else{
      this.isLoading = true;
      this.validarCambioEstatus(elements)
        .subscribe(resultAcept =>{
          //this.selection.clear();
          console.log(resultAcept);
          if(!resultAcept.valido){
            this.dialog.open(ErrorComponent,{data:{error:"Está intentando aprobar uno o más pagos que ya había aprobado antes."}});
          }else{
            //if(!this.validarRechazo(elements)){
              //this.dialog.open(ErrorComponent,{data:{error:"Al menos un registro ya fue rechazado."}});
            //}else{
              
              console.log('Aprobar Pago')
              const dialogRef = this.dialog.open(ConfirmRevisionComponent,{data:{pagos:elements, status: 'Aprobar'}});
  
              dialogRef.afterClosed().subscribe(result =>{
                console.log('Registros a aprobar '+result);
                if(result){
                console.log('Se aceptan pagos aprobar');
                let valores = localStorage.getItem('userCustom');
                console.log(JSON.parse(valores).given_name + ' ' + JSON.parse(valores).family_name); 
                console.log(resultAcept.usuario);
                console.log('detalle total '+result);
                  if(result){
                    console.log('Se aceptan pagos');
                    const x: Stage[] = [];
                    elements.forEach( element =>{
                      console.log(element.Clave);
                      let fechaActual = this.utils.getActualDate();
                      x.push({
                        fecha: fechaActual,
                        claveRetiro: element.Clave,
                        tipoStatus: 'AR',
                        status: 'APR',
                        observaciones: '',
                        usuario: JSON.parse(valores).given_name + ' ' + JSON.parse(valores).family_name,
                        usuarioNm: resultAcept.usuario
                      });
                    });
                    this.consumeService.actualizarEstatusPago(x).subscribe( data => {
                      console.log("updateStage: "+data);
                      this.dialog.open(ConfirmComponent,{data:{error:"Se aprobo correctamente su(s) pago(s)"}});
                      this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
                      this.ngOnInit();
                    }, err => console.log(err) );
                  }else{
                    console.log('Se rechazan pagos');
                    this.isLoading = false;
                  }
                }else{
                console.log('Se rechazan pagos aprobar');
                this.isLoading = false;
                }
              });
            //}
          }
        }, err => {
          console.log(err);
          this.isLoading = false;
        });
    }
  }

  enviarPago(){
    var elements : Bandeja[] = this.selection.selected;

    if(elements.length <= 0){
      this.dialog.open(ErrorComponent,{data:{error:"Seleccione al menos un registro a enviar."}});
    }else{
        const dialogRef = this.dialog.open(ConfirmRevisionComponent,{data:{pagos:this.selection.selected, status: 'Enviar'}});

        dialogRef.afterClosed().subscribe(result =>{
            console.log('Registros a aprobar '+result);
            if(result){
            this.isLoading = true;
            console.log('Se aceptan pagos aprobar');
            const x: CrearArchivo[] = [];
            elements.forEach( element =>{
              console.log(element.Clave);
              let fechaActual = this.utils.getActualDate();
              x.push({
                fecha: fechaActual,
                claveRetiro: element.Clave
              });
            });
            console.log('element',x)
            this.consumeService.crearArchivosPension(x).subscribe( data => {
              console.log("ResponsePensionFile: "+data);
              this.isLoading = false;
              const archivosProcesar: ArchivoBanamex[] = [];
              data.forEach((item) => {
                archivosProcesar.push({
                  fileName: item.Nom_Archivo,
                  fileContent: item.Archivo,
                  claveRetiro: item.Clave_Retiro
                });
              });
              this.enviarDocumentoBnmx(archivosProcesar);
            }, err => {
              console.log(err);
              this.isLoading = false;
             } );
            }else{
            console.log('Se rechazan pagos aprobar');
            }
        });
    }
  }

  enviarDocumentoBnmx(archivos : ArchivoBanamex[]){
    let valores = localStorage.getItem('userCustom');
    this.consumeService.enviarArchivosBanamex(archivos).subscribe( data => {
      this.respuestaArchivosS3 = data;
      console.log("Response s3: "+this.respuestaArchivosS3);
      const x: Stage[] = [];
      let statusOk = false;
      this.respuestaArchivosS3.forEach(e => {
        if(e.statusS3!=='ERR'){
          statusOk = true;
          console.log("Agregando componente",e)
          let fechaActual = this.utils.getActualDate();
          x.push({
            fecha: fechaActual,
            claveRetiro: e.claveRetiro,
            tipoStatus: 'ETAPA',
            status: 'E',
            observaciones: '',
            usuario: '',
            usuarioNm: ''
          });
        }
      });
      if(statusOk){
        this.consumeService.actualizarEstatusPago(x).subscribe( estatus => {
          console.log("update Entregado: "+estatus);
          this.ngOnInit();
          this.dialog.open(ConfirmComponent,{data:{error:"Se envío correctamente su(s) pago(s)"}});
          this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
        }, err => console.log(err) );
      }
    }, err => {
      console.log(err);
      this.isLoading = false;
     } );
  }

  loadBackMenu(){
    this.index = Number(localStorage.getItem('tabIndexSet'));
    if(this.index === 0){
      this.showRech = false;
      this.showAprob = false;
      this.showEnv = false;
  }else if(this.index === 1){
      this.showRech = true;
      this.showAprob = true;
      this.showEnv = false;
  }else if(this.index === 2){
      this.showRech = true;
      this.showAprob = true;
      this.showEnv = false;
  }else if(this.index === 3){
      this.showRech = false;
      this.showAprob = false;
      this.showEnv = true;
  }else if(this.index === 4){
      this.showRech = false;
      this.showAprob = false;
      this.showEnv = false;
  }
  this.retiroList =[];
  this.depositosList = [];
  this.cuentaRetiroList = [];
  this.invokeCatalog();
  }

  search(status:any): ParamsConsult {
    let fechaActual = this.utils.getActualDate();
    const x: ParamsConsult = {
      fecha: fechaActual,
      estado: status,
      pagina: '1',
      registros: '200',
      idProceso: '',
      claveRetiro: ''
    };
    return x;
  }
  
}

export interface Review {
  tipo: string;
  cuenta: number;
  clave: string;
  tipoRetiro: string;
  monto: string;
  operaciones: number;
  estatus: string;
}

export interface Custom{
  valido:boolean,
  usuario:string
}

export interface ResponsePensionFile{
  Clave_Retiro:string;
  Nom_Archivo:string;
  Archivo:string;
}

export interface ArchivoBanamex{
  fileName:string;
  fileContent:string;
  claveRetiro:string;
}

export interface ResponseArchivoBanamex{
  fileName:string;
  statusS3:string;
  detailError:string;
  claveRetiro:string;
}