<mat-dialog-actions align="end">
    <button class="button-close-modal" (click)="close()">
        <img src="../../../../../assets/img/icon/close.svg">
    </button>
</mat-dialog-actions>

<mat-dialog-content class="mat-typography">
    <div mat-dialog-title class="font-22 font-400 text-gray text-center margin-bottom-2">Detalle de la operación</div>

    <div mat-dialog-subtitle class="font-18 font-600 text-gray margin-bottom-1">Retiro por Aportaciones Voluntarias AV220525, Cuenta de Retiro: 87 7677699</div>

    <table mat-table [dataSource]="dataSource" class="table-content">

        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
        </ng-container>

        <ng-container matColumnDef="solicitud">
            <th mat-header-cell *matHeaderCellDef> Solicitud </th>
            <td mat-cell *matCellDef="let element"> {{element.solicitud}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
        </ng-container>

        <ng-container matColumnDef="referencia">
            <th mat-header-cell *matHeaderCellDef> Referencia </th>
            <td mat-cell *matCellDef="let element"> {{element.referencia}} </td>
        </ng-container>

        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let element"> {{element.monto}} </td>
        </ng-container>

        <ng-container matColumnDef="banco">
            <th mat-header-cell *matHeaderCellDef> Banco </th>
            <td mat-cell *matCellDef="let element" class="font-600"> {{element.banco}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="total ds-flex-end-center">
        <div class="margin-right-18 font-16 font-600 text-gray">
            <span class="margin-right-1">Monto total</span><span>$1,000.00</span>
        </div>
    </div>
    <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
</mat-dialog-content>