<div [hidden]="!isLoading" class="loader-wrapper">
    <div class="spinner">  
    </div>
</div>

<div class="ds-flex-end position-custom">
    <button class="button-close-modal" (click)="onClose(true)">
        <img src="../../../../../assets/img/icon/close.svg">
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <div mat-dialog-title class="font-22 font-400 text-blue text-center">¿Deseas descargar el documento .pdf completo o por operaciones específicas?</div>

    <div mat-dialog-subtitle class="font-18 text-gray-dark text-center">{{detalle.Tipo_Retiro}}</div>

    <table class="w-100 font-16">
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Cuenta Retiro:</td>
            <td class="text-black p-l-05 w-50">{{detalle.Cuenta_Retiro}}</td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Clave:</td>
            <td class="text-black p-l-05 w-50">{{detalle.Clave}}</td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Monto:</td>
            <td class="text-black p-l-05">{{detalle.Monto_Total | currency: 'MXN':"symbol-narrow"}} </td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">No. Operaciones:</td>
            <td class="text-black p-l-05">{{detalle.Num_Operaciones}}</td>
        </tr>
    </table>

</mat-dialog-content>
<div [hidden]="isLoading">
<mat-dialog-actions class="ds-flex-between">
    <button class="button-white-normal" (click)="openDetail()">
        Descargar por operación
    </button>

    <button class="button-blue-normal" (click)="createPdf()">
        Descargar completo
    </button>
</mat-dialog-actions>
</div>