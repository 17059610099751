<mat-dialog-content class="mat-typography">
    <div mat-dialog-title class="font-22 font-400 text-blue text-center">¿Seguro que desea cerrar sesión?</div>
</mat-dialog-content>

<mat-dialog-actions class="ds-flex-between margin-top-1">
    <button class="button-white-normal" (click)="logout()">
        Si
    </button>

    <button class="button-blue-normal" (click)="onClose()">
        No
    </button>
</mat-dialog-actions>
