import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-duplicate-request',
  templateUrl: './duplicate-request.component.html',
  styleUrls: ['./duplicate-request.component.scss']
})
export class DuplicateRequestComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DuplicateRequestComponent>) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(); 
  }

}
