<form [formGroup]="searchForm" class="ds-flex-between banner-filter">
    <div class="ds-flex-column w-28">
        <mat-label class="font-16 text-white">Cliente o Referencia</mat-label>
        <mat-form-field class="example-full-width">
            <input type="text" matInput formControlName="nameFilter" (blur)="applyFilter()">
        </mat-form-field>
    </div>
</form>

<div class="container-component">
    <div class="ds-flex-align-center pointer margin-bottom-1" [routerLink]="['..']">
        <img class="margin-right-1" src="../../../../../assets/img/icon/Vector.svg">
        <span class="font-15 breadcrumd">Regresar</span>
    </div>

    <div class="font-20 font-600 text-gray margin-bottom-1">Retiro por Aportaciones Voluntarias</div>
    <div class="ds-flex margin-bottom-1">
        <div class="font-20 text-gray margin-right-3">
            <div class="margin-bottom-1">
                <span class="margin-right-05">Cuenta:</span>
                <span class="font-600">{{detailHeader.Cuenta_Retiro}}</span>
            </div>
            <div>
                <span class="margin-right-05">Estatus:</span>
                <span class="font-600">2/2</span>
            </div>
        </div>
        <div class="font-20 text-gray margin-right-3">
            <div class="margin-bottom-1">
                <span class="margin-right-05">Clave:</span>
                <span class="font-600">{{detailHeader.Clave}}</span>
            </div>
            <div class="ds-flex">
                <span class="margin-right-05">Aprobado por:</span>
                <div *ngIf="detailHeader.Aprobador_1 !== null" class="font-600">{{detailHeader.Aprobador_1 | titlecase}}</div>
                <div *ngIf="detailHeader.Aprobador_2 !== null" class="font-600">{{detailHeader.Aprobador_2 | titlecase}}</div>
            </div>
        </div>
        <div class="font-20 text-gray margin-right-3">
            <div class="margin-bottom-1">
                <span class="margin-right-05">No. Operaciones:</span>
                <span class="font-600">{{detailHeader.Num_Operaciones}}</span>
            </div>
            <div>
                <span class="margin-right-05">Monto total:</span>
                <span class="font-600">{{detailHeader.Monto_Total | currency: "MXN":"symbol-narrow"}}</span>
            </div>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="table-content">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="w-0">

            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Tipo de depósito </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.tipo}} </td>
        </ng-container>

        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Monto </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.monto | currency: "MXN":"symbol-narrow"}} </td>
        </ng-container>

        <ng-container matColumnDef="solicitud">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Solicitud </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.solicitud}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Estatus </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.Status}} </td>
        </ng-container>

        <ng-container matColumnDef="referencia">
            <th mat-header-cell *matHeaderCellDef> Referencia </th>
            <td mat-cell *matCellDef="let element"> {{element.referencia}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>

    <div class="ds-flex-end margin-top-1">
        <button class="button-blue-normal" (click)="createPdf()">Descargar .pdfs</button>
    </div>
</div>