import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ParamsConsult } from 'src/app/modules/inbox/components/inbox/inbox.component';
import { ConsumeService } from 'src/app/services/consume.services';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss']
})
export class ReviewDetailComponent implements OnInit {

  bandejaFull: Detail[] = [];
  dataSourceBackUp: Detail[] = [];
  totalFinal: Detail[] = [];
  detailHeader: HeaderDetail = {
    Cuenta_Retiro: '',
    Clave: '',
    Num_Operaciones: '',
    Monto_Total: '',
    Tipo_Retiro: '',
    Aprobador_1: '',
    Aprobador_2: '',
    etapa:''
  };
  bF: any = [];
  searchForm = this.fb.group({
    nameFilter: ['']
  });
  @ViewChild(MatPaginator) paginator : MatPaginator;
  isLoading: boolean = true;

  constructor(private router: Router,
    private activeRouter: ActivatedRoute,
    private consumeService: ConsumeService,
    private fb: FormBuilder, private utils: UtilsService) {
      
    }
  
  generateData(data: any, tipoPago: string): Detail[] {
    const x: Detail[] = [];
    data.forEach((e: any) => {
      x.push({
        tipo: tipoPago,
        monto: e.Monto_Pago,
        solicitud: e.Num_Solicitud,
        cliente: e.Primer_Nombre,
        referencia: e.Referencia
      });
    });
    return x;
  }

  search(status:any): ParamsConsult {
    let fechaActual = this.utils.getActualDate();
    const x: ParamsConsult = {
      fecha: fechaActual,
      estado: status,
      pagina: '1',
      registros: '200',
      idProceso: history.state.hello.Id_Proceso,
      claveRetiro: history.state.hello.Clave
    };
    return x;
  }


  ngOnInit() {
    console.log(history.state);
    this.detailHeader.Clave = history.state.hello.Clave;
    this.detailHeader.Cuenta_Retiro = history.state.hello.Cuenta_Retiro;
    this.detailHeader.Num_Operaciones = history.state.hello.Num_Operaciones;
    this.detailHeader.Monto_Total = history.state.hello.Monto_Total;
    this.detailHeader.Tipo_Retiro = history.state.hello.Tipo_Retiro;
    this.detailHeader.Aprobador_1 = history.state.hello.Aprobador_1;
    this.detailHeader.Aprobador_2 = history.state.hello.Aprobador_2;
    this.detailHeader.etapa = history.state.etapa;
    let busqueda = this.search(history.state.status);
    this.consumeService.obtenerDetalle(busqueda)
    .subscribe( data => {
      console.log("detalle: "+data);
      this.respuetaCompleta = data;
      this.bandejaFull = this.generateData(data.PagoResponses, history.state.hello.Tipo_Pago);
      this.dataSourceBackUp = this.bandejaFull;
      this.dataSource = new MatTableDataSource<Detail>(this.bandejaFull);
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    }, err => {
      console.log(err) ;
      this.isLoading = false;
    });

    
    
    this.activeRouter.params.subscribe(
      (event) => {
        const { idClient } = event;
      },
      (err) => console.log(err)
    );
  }

  displayedColumns: string[] = ['tipo', 'monto', 'solicitud', 'cliente', 'referencia'];
  dataSource = new MatTableDataSource<Detail>();
  respuetaCompleta:any;

  applyFilter() {
    if(this.searchForm.controls.nameFilter.value !== ''){
      this.totalFinal = this.bandejaFull.filter((item) => 
        item.cliente === this.searchForm.controls.nameFilter.value || item.referencia === this.searchForm.controls.nameFilter.value
    );
    }else{
      this.totalFinal = this.bandejaFull;
    }
    this.dataSource = new MatTableDataSource<Detail>(this.totalFinal);
    this.dataSource.paginator = this.paginator;
  }

}

export interface Detail {
  tipo: string;
  monto: string;
  solicitud: number;
  cliente: string;
  referencia: number;
}

export interface HeaderDetail {
  Cuenta_Retiro: string;
  Clave: string;
  Num_Operaciones: string;
  Monto_Total: string;
  Tipo_Retiro: string;
  Aprobador_1: string;
  Aprobador_2: string;
  etapa: string
}