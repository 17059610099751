import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationResponseComponent } from '../modals/notification-response/notification-response.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  retiros = new FormControl('');
  retiroList: string[] = ['Todos', 'Retiro por Aportaciones Voluntarias', 'Retiro por Autorizacion de Pension', 'Retiro por Autorizacion ISSSTE', 'Retiro por Plan Privado ISSSTE', 'Reexpedicion por vigencia por Desempleo', 'Retiro por Autorizacion de Pension', 'Retiro por Autorizacion ISSSTE'];
  depositos = new FormControl('');
  depositosList: string[] = ['Todos', 'SPEI', 'OPR'];
  cuentaRetiro = new FormControl('');
  cuentaRetiroList: string[] = ['Todas', '877677699', '14008004'];

  showComponent: boolean = true;

  constructor(public dialog: MatDialog, public route: ActivatedRoute) {}

  ngOnInit(): void {

  }

  displayedColumns: string[] = ['fecha', 'tipo', 'cuenta', 'clave', 'tipoRetiro', 'opEnviados', 'montoEnviados', 'opAplicados', 'montoAplicados', 'opNoAplicados', 'montoNoAplicados', 'status', 'comprobante'];
  selection = new SelectionModel<Response>(true, []);
  dataSourceAll = new MatTableDataSource<Response>(ELEMENT_DATA_ALL);

  openDialogNotify() {
    const dialogRef = this.dialog.open(NotificationResponseComponent, {
      width: '40%'
    });
  }

  hideComponent() {
    this.showComponent = !this.showComponent;
  }

}

const ELEMENT_DATA_ALL: Response[] = [
  { fecha: "07/feb/23" ,tipo: "SPEI", cuenta: 14008004, clave: 'BB004456', tipoRetiro: 'Retiro por Autorizacion ISSSTE', opEnviados: 54, montoEnviados: '$22,765.00', opAplicados: 50, montoAplicados: "$22,765.00", opNoAplicados: 4, montoNoAplicados: "$2,600.00" },
  { fecha: "07/feb/23" ,tipo: "OPR PAGO ", cuenta: 877677699, clave: 'AV220525', tipoRetiro: 'Retiro por Autorizacion ISSSTE', opEnviados: 12, montoEnviados: '$64,990.80', opAplicados: 11, montoAplicados: "$64,990.80", opNoAplicados: 1, montoNoAplicados: "$4,200.80" }
];

export interface Response {
  fecha: string;
  tipo: string;
  cuenta: number;
  clave: string;
  tipoRetiro: string;
  opEnviados: number;
  montoEnviados: string;
  opAplicados: number;
  montoAplicados: string;
  opNoAplicados: number;
  montoNoAplicados: string;
}