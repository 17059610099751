import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
providedIn: 'root',
})
export class UtilsService{

    constructor(){}

    getActualDate(){
        let pipe = new DatePipe('es-MX')
        return pipe.transform(new Date(),'yyyy-MM-dd')
    }
}