<div *ngIf="showComponent" class="ds-flex-between banner-filter">
    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Proceso / Tipo de retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="retiros" multiple>
                <mat-option *ngFor="let retiro of retiroList" [value]="retiro">{{retiro}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-15">
        <mat-label class="font-16 text-white">Tipo de pago</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="depositos" multiple>
                <mat-option *ngFor="let deposito of depositosList" [value]="deposito">{{deposito}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-15">
        <mat-label class="font-16 text-white">Cuenta retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="cuentaRetiro" multiple>
                <mat-option *ngFor="let cuenta of cuentaRetiroList" [value]="cuenta">{{cuenta}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Clave o Monto</mat-label>
        <mat-form-field class="pointer">
            <input type="text" matInput>
            <mat-icon matSuffix><img src="../../../../../assets/img/icon/fi-rr-search.svg"></mat-icon>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-22">
        <mat-label class="font-16 text-white">Rango fecha:</mat-label>
        <mat-form-field appearance="fill">
            <div class="ds-flex-center-between">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="DD-M-MYY">
                    <input matEndDate placeholder="DD-MM-YY">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
        </mat-form-field>
    </div>
</div>

<div class="container-component">
    <div *ngIf="showComponent" class="ds-flex-center-between text-gray margin-bottom-1">
        <div class="font-20">
            <div class="ds-flex-align-center margin-bottom-02" [routerLink]="['..']">
                <img class="margin-right-1" src="../../../../../assets/img/icon/Vector.svg">
                <span class="font-15 breadcrumd">Regresar</span>
            </div>

            <span class="margin-right-04 font-600">2</span>
            <span class="margin-right-04">Respuestas</span>
        </div>

        <div class="ds-flex font-18">
            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">OPR</span>
                    <span class="margin-right-04 font-600">$ 40,788.30</span>
                </div>

                <div>
                    <span class="margin-right-04">SPEI</span>
                    <span class="margin-right-04 font-600">$ 85,455.00</span>
                </div>
            </div>

            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">...7699</span>
                    <span class="margin-right-04 font-600">$ 97,788.30</span>
                </div>

                <div>
                    <span class="margin-right-04">...8004</span>
                    <span class="margin-right-04 font-600">$ 22,765.00</span>
                </div>
            </div>

            <div class="ds-flex-column padding-0-05">
                <div>
                    <span class="margin-right-04">Total</span>
                    <span class="margin-right-04 font-600">$ 127,876.30</span>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div *ngIf="showComponent" class="text-center font-22 text-blue margin-top-2 margin-bottom-2">¿Desea notificar las siguientes
            respuestas?</div>

        <div *ngIf="!showComponent" class="ds-flex-column-center margin-top-3">
            <img src="../../../../../assets/img/checked.svg">
            <div class="text-center font-22 text-blue margin-top-2 margin-bottom-2">Las siguientes respuestas han sido notificadas:</div>
        </div>

        <section class="overflow-x">
            <table mat-table [dataSource]="dataSourceAll" class="table-content">

                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="w-col-1"> Fecha </th>
                    <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef class="w-col-2"> Tipo de pago </th>
                    <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
                </ng-container>

                <ng-container matColumnDef="cuenta">
                    <th mat-header-cell *matHeaderCellDef class="w-col-3"> Cuenta retiro </th>
                    <td mat-cell *matCellDef="let element"> {{element.cuenta}} </td>
                </ng-container>

                <ng-container matColumnDef="clave">
                    <th mat-header-cell *matHeaderCellDef class="w-col-4"> Clave </th>
                    <td mat-cell *matCellDef="let element"> {{element.clave}} </td>
                </ng-container>

                <ng-container matColumnDef="tipoRetiro">
                    <th mat-header-cell *matHeaderCellDef class="w-col-5"> Tipo de retiro </th>
                    <td mat-cell *matCellDef="let element" class="border-right-2"> {{element.tipoRetiro}} </td>
                </ng-container>

                <ng-container matColumnDef="opEnviados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-6"> No. Op. </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.opEnviados}} </td>
                </ng-container>

                <ng-container matColumnDef="montoEnviados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-7"> Monto </th>
                    <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.montoEnviados}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="opAplicados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-8"> No. Op. </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.opAplicados}} </td>
                </ng-container>

                <ng-container matColumnDef="montoAplicados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-9"> Monto </th>
                    <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.montoAplicados}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="opNoAplicados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-10"> No. Op. </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.opNoAplicados}} </td>
                </ng-container>

                <ng-container matColumnDef="montoNoAplicados">
                    <th mat-header-cell *matHeaderCellDef class="w-col-11"> Monto </th>
                    <td mat-cell *matCellDef="let element" class="border-right-2 text-end"> {{element.montoNoAplicados}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class=""> </th>
                    <td mat-cell *matCellDef="let element" class="border-right-2 text-end">
                        <div class="box-status color-status-red"></div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="comprobante">
                    <th mat-header-cell *matHeaderCellDef> Motivo Notificación </th>
                    <td mat-cell *matCellDef="let element" class="show-detail">
                        <div class="ds-flex">
                            <button class="ds-flex-align-center detail-button" (click)="openDialogNotify()">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-edit.svg">
                                <div class="font-15 text-white">Motivo</div>
                            </button>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
                </tr>
            </table>
        </section>

        <div class="ds-flex-center">
            <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
        </div>

        <div *ngIf="showComponent" class="ds-flex-end margin-top-3">
            <button class="button-blue-normal" (click)="hideComponent()">Notificar Respuestas</button>
        </div>

        <div *ngIf="!showComponent" class="ds-flex-end margin-top-3">
            <button class="button-blue-normal" [routerLink]="['..']">Ir a Respuestas</button>
        </div>
    </div>
</div>