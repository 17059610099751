<mat-dialog-content class="mat-typography content-modal">
    <div class="ds-flex-between">
        <div class="ds-flex-align-center">
            <img class="margin-right-2" src="../../../../../assets/img/icon/warning-alert.svg">
            <div>Se enviarán los siguientes pagos a {{status}}</div>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="table-content">

        <ng-container matColumnDef="cuenta">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Cuenta Retiro </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.Cuenta_Retiro}} </td>
        </ng-container>

        <ng-container matColumnDef="clave">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Clave </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.Clave}} </td>
        </ng-container>

        <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Monto </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div style="text-align:right; margin-top:5%">
        <button class="button-blue-normal margin-right-1" (click)="close(true)">Aceptar</button>
        <button class="button-white-normal" (click)="close(false)">Cancelar</button>
    </div>
</mat-dialog-content>