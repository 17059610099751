import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public showPassword: boolean = false;
  public formLogin = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });
 
  constructor(
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private router: Router
  ) {
   }

  ngOnInit(): void {
  }
  
  //urlExternalLogin = 'https://hth-user-pool-develop.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Azure&redirect_uri=http://localhost:4200/&response_type=code&client_id=1h1qbhmkv8tbpmv1dq1afkno9o&scope=phone%20email%20openid%20profile';  
  urlExternalLogin = environment.urlExternalLogin;

  async login(){
    console.log('Validando inicio se sesion totals');
    const time = new Date().getTime();
    localStorage.setItem('HTH', time.toString());
    window.location.assign(this.urlExternalLogin);
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

 


  //authservice
  

}