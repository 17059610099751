<div [hidden]="!isLoading" class="loader-wrapper">
    <div class="spinner">
        
    </div>
</div>


<form [formGroup]="searchForm" class="ds-flex-between banner-filter" >
    <div class="ds-flex-column w-28">
        <mat-label class="font-16 text-white">Proceso / Tipo de retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="retiros" multiple>
                <mat-option *ngFor="let retiro of retiroList" [value]="retiro" (onSelectionChange)="getValues($event,'retiro')">{{retiro.Desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-20">
        <mat-label class="font-16 text-white">Tipo de pago</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="depositos" multiple>
                <mat-option *ngFor="let deposito of depositosList" [value]="deposito" (onSelectionChange)="getValues($event,'deposito')">{{deposito.Desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-20">
        <mat-label class="font-16 text-white">Cuenta retiro</mat-label>
        <mat-form-field appearance="fill">
            <mat-select [formControl]="cuentaRetiro" multiple>
                <mat-option *ngFor="let cuenta of cuentaRetiroList" [value]="cuenta" (onSelectionChange)="getValues($event,'cuenta')">{{cuenta.Desc}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="ds-flex-column w-28">
        <mat-label class="font-16 text-white">Clave o Monto</mat-label>
        <mat-form-field class="example-full-width pointer">
            <input type="text" matInput formControlName="nameFilter" (blur)="getValuesCustom()" >
        </mat-form-field>
    </div>
</form>

<div class="container-component" >
    <div class="ds-flex-center-between text-gray margin-bottom-1">
        <div class="font-20">
            <span class="margin-right-04 font-600">{{this.detallePeticion.Tot_Regs}}</span>
            <span class="margin-right-04">Solicitudes en revisión:</span>
            <span class="margin-right-04">Todas</span>
        </div>

        <div class="ds-flex font-18">
            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">OPR</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_Opr | currency: "MXN":"symbol-narrow"}}</span>
                </div>

                <div>
                    <span class="margin-right-04">SPEI</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_SPEI | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>

            <div class="ds-flex-column border-box-right padding-0-05">
                <div>
                    <span class="margin-right-04">...7699</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_7699 | currency: "MXN":"symbol-narrow"}}</span>
                </div>

                <div>
                    <span class="margin-right-04">...8004</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Total_8004 | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>

            <div class="ds-flex-column padding-0-05">
                <div>
                    <span class="margin-right-04">Total</span>
                    <span class="margin-right-04 font-600">{{this.detallePeticion.Tot_General | currency: "MXN":"symbol-narrow"}}</span>
                </div>
            </div>
        </div>
    </div>
    
    <div [hidden]="isLoading" >
        <mat-tab-group (selectedTabChange)="onTabChanged($event);" [selectedIndex]="tabIndexSet">
            <mat-tab>
                <ng-template mat-tab-label class="primary ds-flex-align-center">
                    <div class="ds-flex-align-end">
                        <mat-icon class="margin-right-05">
                            <img src="../../../../../assets/img/icon/fi-rr-inbox-tab.svg">
                        </mat-icon>
                        <div class="font-16 text-gray">Todas</div>
                    </div>
                </ng-template>

                <table mat-table [dataSource]="dataSourceAll" class="table-content">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
            
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo de pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cuenta">
                        <th mat-header-cell *matHeaderCellDef> Cuenta retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoRetiro">
                        <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clave">
                        <th mat-header-cell *matHeaderCellDef> Clave </th>
                        <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operaciones">
                        <th mat-header-cell *matHeaderCellDef> No. de operaciones </th>
                        <td mat-cell *matCellDef="let element" class="text-end"> {{element.Num_Operaciones}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto">
                        <th mat-header-cell *matHeaderCellDef> Monto </th>
                        <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status_Op}} </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element" class="show-detail">
                            <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                                <div class="font-15 text-white">Ver detalle</div>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </tr>
                </table>
                <mat-paginator #MatPaginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>

            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="ds-flex-align-end" >
                        <mat-icon class="margin-right-05">
                            <img src="../../../../../assets/img/icon/fi-rr-alarm-clock-tab.svg">
                        </mat-icon>
                        <div class="font-16 text-gray">Pendientes 0/2</div>
                    </div>

                </ng-template>
                <table mat-table #MatTable1 [dataSource]="dataSourceErrings" class="table-content">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
            
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox   (click)="$event.stopPropagation()" 
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo de pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cuenta">
                        <th mat-header-cell *matHeaderCellDef> Cuenta retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoRetiro">
                        <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clave">
                        <th mat-header-cell *matHeaderCellDef> Clave </th>
                        <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operaciones">
                        <th mat-header-cell *matHeaderCellDef> No. de operaciones </th>
                        <td mat-cell *matCellDef="let element" class="text-end"> {{element.Num_Operaciones}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto">
                        <th mat-header-cell *matHeaderCellDef> Monto </th>
                        <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status_Op}} </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element" class="show-detail">
                            <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                                <div class="font-15 text-white">Ver detalle</div>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </tr>
                </table>
                <mat-paginator #MatPaginator2 [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="ds-flex-align-end" >
                        <mat-icon class="margin-right-05">
                            <img src="../../../../../assets/img/icon/fi-rr-alarm-clock-tab.svg">
                        </mat-icon>
                        <div class="font-16 text-gray">Pendientes 1/2</div>
                    </div>
                </ng-template>

                <table mat-table [dataSource]="dataSource12" class="table-content">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
            
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo de pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cuenta">
                        <th mat-header-cell *matHeaderCellDef> Cuenta retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoRetiro">
                        <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clave">
                        <th mat-header-cell *matHeaderCellDef> Clave </th>
                        <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operaciones">
                        <th mat-header-cell *matHeaderCellDef> No. de operaciones </th>
                        <td mat-cell *matCellDef="let element" class="text-end"> {{element.Num_Operaciones}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto">
                        <th mat-header-cell *matHeaderCellDef> Monto </th>
                        <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status_Op}} </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element" class="show-detail">
                            <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                                <div class="font-15 text-white">Ver detalle</div>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" >
                    </tr>
                </table>
                <mat-paginator #MatPaginator3 [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="ds-flex-align-end" >
                        <mat-icon class="margin-right-05">
                            <img src="../../../../../assets/img/icon/fi-rr-comment-check-tab.svg">
                        </mat-icon>
                        <div class="font-16 text-gray">Aprobadas</div>
                    </div>
                </ng-template>

                <table mat-table [dataSource]="dataSourceApproved" class="table-content">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
            
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo de pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cuenta">
                        <th mat-header-cell *matHeaderCellDef> Cuenta retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoRetiro">
                        <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clave">
                        <th mat-header-cell *matHeaderCellDef> Clave </th>
                        <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operaciones">
                        <th mat-header-cell *matHeaderCellDef> No. de operaciones </th>
                        <td mat-cell *matCellDef="let element" class="text-end"> {{element.Num_Operaciones}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto">
                        <th mat-header-cell *matHeaderCellDef> Monto </th>
                        <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status_Op}} </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element" class="show-detail">
                            <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                                <div class="font-15 text-white">Ver detalle</div>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
                <mat-paginator #MatPaginator4 [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </mat-tab>

            <mat-tab>

                <ng-template mat-tab-label>
                    <div class="ds-flex-align-end" >
                        <mat-icon class="margin-right-05">
                            <img src="../../../../../assets/img/icon/fi-rr-delete-tab.svg">
                        </mat-icon>
                        <div class="font-16 text-gray">Rechazadas</div>
                    </div>

                </ng-template>


                <table mat-table [dataSource]="dataSourceRech" class="table-content">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
            
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo de pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Pago}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cuenta">
                        <th mat-header-cell *matHeaderCellDef> Cuenta retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Cuenta_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoRetiro">
                        <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                        <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="clave">
                        <th mat-header-cell *matHeaderCellDef> Clave </th>
                        <td mat-cell *matCellDef="let element"> {{element.Clave}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operaciones">
                        <th mat-header-cell *matHeaderCellDef> No. de operaciones </th>
                        <td mat-cell *matCellDef="let element"> {{element.Num_Operaciones}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="monto">
                        <th mat-header-cell *matHeaderCellDef> Monto </th>
                        <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef> Estatus </th>
                        <td mat-cell *matCellDef="let element"> {{element.Status_Op}} </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element" class="show-detail" >
                            <button class="ds-flex-align-center detail-button" (click)="showDetail(element)">
                                <img class="margin-right-05" src="../../../../../assets/img/icon/fi-rr-list-button.svg">
                                <div class="font-15 text-white">Ver detalle</div>
                            </button>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>
                </table>
                <mat-paginator #MatPaginator5 [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>
            </mat-tab>
        </mat-tab-group>

        <div class="ds-flex-end margin-top-1">
            <button *ngIf="showRech" class="button-white-normal ds-flex-centers margin-right-1" (click)="rechazarPago()">Rechazar solicitud(es)</button>
            <button *ngIf="showAprob" class="button-blue-normal ds-flex-centers margin-right-1" (click)="aprobarPago()">Aprobar solicitud(es)</button>
            <button *ngIf="showEnv" class="button-blue-normal ds-flex-centers margin-right-1" (click)="enviarPago()">Enviar</button>
        </div>
    </div>

    

</div>
