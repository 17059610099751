import { AfterViewInit, Component } from '@angular/core';
import { Routes } from '@angular/router';
import { InboxComponent } from './modules/inbox/components/inbox/inbox.component';
import { LoginComponent } from './modules/login/components/login/login.component';
import { ResponseComponent } from './modules/response/components/response/response.component';
import { ReviewDetailComponent } from './modules/review/components/review-detail/review-detail.component';
import { ReviewComponent } from './modules/review/components/review/review.component';
import { SidenavComponent } from './modules/shared/components/sidenav/sidenav.component';
import { StatisticsComponent } from './modules/statistics/components/statistics/statistics.component';
import { PATH } from 'src/environments/constants';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { DeclineComponent } from './modules/review/components/decline/decline.component';
import { interval } from 'rxjs';
import { DBConfig, NgxIndexedDBService } from 'ngx-indexed-db';
import { ResponseDetailComponent } from './modules/response/components/response-detail/response-detail.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'principal-front';

  /*
    Tiempo intervalo en el que se debe refrescar el token
    Se mide en milisegundos
    Ejemplo:
      3600000 --> 1 hr
      600000 --> 10 min
  */
  tiempoIntervalo = 60000;
  /*
    Tiempo máximo que debe durar una sesión
    Se mide en horas
    Ejemplo:
      8 --> jornada de 8hrs
  */
  tiempoSesion = 60000;

  constructor(private authService: AuthService, private dbService: NgxIndexedDBService) {

  }
  ngAfterViewInit(): void {
    this.authService.initAuthData();
  }

  ngOnInit(): void {
    //this.validarTiempoAnteriorSesion();
    //this.seguimientoSesion();
  }

  /*
    Función que se encarga de validar que el usuario no exceda la duración de su
    sesión. Se realiza un cierre de sesión si se ha excedido el tiempo. Diseñada
    para el caso donde la interfaz se cierra o el navegador sin dar clic en la
    opción de cerrar sesión.
  */
    validarTiempoAnteriorSesion(): void {
      // obtener la hora en la que se inicio sesion
      console.log('validarTiempoAnteriorSesion');
      let inicio = localStorage.getItem('HTH');
      if (inicio) {
        inicio = inicio.substring(0, inicio.length - 3);
        const tiempoInicioSesion = parseInt(inicio);
        // obtener tiempo actual
        const actual = new Date().getTime();
        const actualString = actual.toString();
        const actualSubString = actualString.substring(0, actualString.length - 3);
        const tiempoActual = parseInt(actualSubString);
        // calcular el tiempo maximo de la sesion apartir de las horas de la jornada laboral
        let tiempoMaxSesion = tiempoInicioSesion + (this.tiempoSesion * 3600);
        // validar si el tiempo de la sesion actual es igual o mayor al maximo para forzar un cierre
        if (tiempoActual >= tiempoMaxSesion) {
          this.authService.signOut();
        }
      }
    }

  /*
    Función que se encarga de refrescar el token cada intervalo de tiempo definido
    actualizando los datos de la sesión actual. Una vez que se ha excedido el
    número de horas de la jornada se cierra sesión en lugar de refrescar el token.
    El forzar el cierre de sesión obliga al usuario a realizar un inicio de sesión
    generando nuevos tokens rehabilitando la funcionalidad de los servicios implementados.
  */
    seguimientoSesion(): void {
      console.log('seguimientoSesion');
      try {
        // generar el intervalo de tiempo en el que se realizara el refresh del token
        const seguimiento = interval(this.tiempoIntervalo);
        seguimiento.subscribe((tiempoTranscurrido) => {
          // validar que el tiempo transcurrido en horas no rebase la joranada
          if (tiempoTranscurrido < this.tiempoSesion) {
            // si no se rebasa la cantidad de horas maximas dentro de la aplicacion se refresca el token
            this.authService.refreshToken();
            console.log("refrescar token")
          } else {
            // si el tiempo transcurrido es mayor se forza el cierre de la sesion
            this.authService.signOut();
            console.log("e.signOut else")
          }
        });
      } catch (err) {
        // si ocurre un error al tratar de validar el estado de la sesion se forza un cierre
        this.authService.signOut();
        console.log("e.signOut error")
      }
    }

    closeSession(){
      this.authService.signOut();
    }
    
}



export const routes: Routes = [
  {
    path: PATH.LOGIN,
    component: LoginComponent
  },
  {
    path: PATH.EMPTY,
    component: SidenavComponent ,
    canActivate: [AuthGuard],
    children: [
      {
        path: PATH.EMPTY,
        component: InboxComponent ,
      },
      {
        path: PATH.INBOX,
        children:[
          {
            path: PATH.EMPTY,
            component: InboxComponent ,
          },
          {
            path: PATH.INBOX_DETAIL,
            component: ReviewDetailComponent
          }
        ]
      },
      {
        path: PATH.REVIEW,
        children:[
          {
            path: PATH.EMPTY,
            component: ReviewComponent,
          },
          {
            path: PATH.INBOX_DETAIL,
            component: ReviewDetailComponent
          },
          {
            path: PATH.DECLINE,
            component: DeclineComponent
          }
        ]
      },
      {
        path: PATH.RESPONSE,         
        children:[
          {
            path: PATH.EMPTY,
            component: ResponseComponent,
          },
          {
            path: PATH.RESPONSE_DETAIL,
            component: ResponseDetailComponent
          }
        ]
      },
      {
        path: PATH.STATISTICS,
        component: StatisticsComponent ,
      }
    ]
  }
];

export const dbConfig: DBConfig  = {
  name: 'MyDb',
  version: 3,
  objectStoresMeta: [{
    store: 'user',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'tipo', keypath: 'tipo', options: { unique: false } },
      { name: 'token', keypath: 'token', options: { unique: false } },
      { name: 'refresh', keypath: 'refresh', options: { unique: false } },
      { name: 'user', keypath: 'user', options: { unique: false } }
    ]
  },
  {
    store: 'catalog',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'value', keypath: 'value', options: { unique: false } }
    ]
  }],
  // provide the migration factory to the DBConfig
  migrationFactory
};

export function migrationFactory() {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    1: (db, transaction) => {
      const store = transaction.objectStore('people');
      store.createIndex('country', 'country', { unique: false });
    },
    3: (db, transaction) => {
      const store = transaction.objectStore('people');
      store.createIndex('age', 'age', { unique: false });
    }
  };
}