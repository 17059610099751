<mat-dialog-content class="mat-typography">
    <div class="ds-flex-column-center">
        <div mat-dialog-title class="font-22 font-400 text-blue text-center">Está intentando aprobar uno o más pagos que ya había aprobado antes</div>

        <img class="w-12" src="../../../../../../assets/img/icon/warning-alert.svg">
    </div>
</mat-dialog-content>

<mat-dialog-actions class="ds-flex-center margin-top-1">
    <button class="button-blue-normal" (click)="onClose()">
        Entendido
    </button>
</mat-dialog-actions>

