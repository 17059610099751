import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class AppInitService {

}

export function getConfigPaginator() {
    const paginatorIntl = new MatPaginatorIntl();
  
    paginatorIntl.itemsPerPageLabel = 'Por página: ';
    paginatorIntl.nextPageLabel = 'Página siguiente';
    paginatorIntl.previousPageLabel = 'Página previa';
    paginatorIntl.firstPageLabel = 'Primer página';
    paginatorIntl.lastPageLabel = 'Última página';
    paginatorIntl.getRangeLabel = rangeLabel;    
  
    return paginatorIntl;
  }

  const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }