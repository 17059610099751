import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl } from '@angular/forms';
import { DownloadResponseComponent } from '../modals/download-response/download-response.component';
import { NotificationResponseComponent } from '../modals/notification-response/notification-response.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Bandeja, Catalogo, DetalleBandeja, ParamsConsult } from 'src/app/modules/inbox/components/inbox/inbox.component';
import { ConsumeService } from 'src/app/services/consume.services';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { ErrorComponent } from 'src/app/modules/modals/error/error.component';
import { DataBaseService } from 'src/app/services/database.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModifyCountEmmiter } from 'src/app/modules/inbox/components/inbox/modify-count-emmiter';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss']
})
export class ResponseComponent implements OnInit {

  retiros = new FormControl('');
  retiroList: Catalogo[] = [];
  depositos = new FormControl('');
  depositosList: Catalogo[] = [];
  cuentaRetiro = new FormControl('');
  cuentaRetiroList: Catalogo[] = [];

  dataSourceAll = new MatTableDataSource<Bandeja>();
  bandejaFull: Bandeja[] = [];
  dataSourceBackUp: Bandeja[] = [];
  searchForm = this.fb.group({
    nameFilter: ['']
  });
  detallePeticion : DetalleBandeja = {
    Tot_Regs: '',
    Total_Opr: '',
    Total_SPEI: '',
    Total_7699: '',
    Total_8004: '',
    Tot_General: ''
  }; 
  total: Bandeja[] = [];
  bF: String[] = [];
  bFtipoPago: String[] = [];
  bFcuentaRetiro: String[] = [];
  totalFinal: Bandeja[] = [];
  @ViewChild(MatPaginator) paginator : MatPaginator;

  constructor(public dialog: MatDialog, public route: ActivatedRoute,private fb: FormBuilder,
    private consumeService: ConsumeService,private router: Router, public dataBase:DataBaseService,
    private utils: UtilsService, public totalEmmit:ModifyCountEmmiter) {}

  ngOnInit(): void {
    this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
    localStorage.setItem('tabIndexSet', '0');
    this.invokeCatalog();
    this.invokeBandeja();
  }

  displayedColumns: string[] = ['select', 'fecha', 'tipo', 'cuenta', 'clave', 'tipoRetiro', 'opEnviados', 'montoEnviados', 'opAplicados', 'montoAplicados', 'opNoAplicados', 'montoNoAplicados', 'status', 'comprobante'];
  selection = new SelectionModel<Bandeja>(true, []);

  openDialogPdf(renglon:any) {
    var elements : Bandeja = renglon;
    const dialogRef = this.dialog.open(DownloadResponseComponent,{data:{data:renglon,status:'B'}});

    dialogRef.afterClosed().subscribe((result) => {
      //this.router.navigate(['dashboard/response/detail']);
      if(!result){
        console.log('Mandando a detalle'+renglon);
        this.router.navigate(['response/detail'], { state: { hello: renglon, status:'B' } });
      }
    });
  }

  openDialogNotify() {
    var elements : Bandeja[] = this.selection.selected;

    if(elements.length <= 0){
      this.dialog.open(ErrorComponent,{data:{error:"Seleccione al menos un registro para enviar a Notificar Pagos."}});
    }else{
      const dialogRef = this.dialog.open(NotificationResponseComponent, {
        width: '40%'
      });
    }
    
  }

  invokeBandeja(){
    let busqueda = this.search('E');
    this.consumeService.obtenerPagos(busqueda)
    .subscribe( data => {
      console.log("token: "+data.ResumenResponses);
      this.detallePeticion.Tot_Regs = data.Tot_Regs;
      this.detallePeticion.Total_Opr = data.Total_Opr;
      this.detallePeticion.Total_SPEI = data.Total_SPEI;
      this.detallePeticion.Total_7699 = data.Total_7699;
      this.detallePeticion.Total_8004 = data.Total_8004;
      this.detallePeticion.Tot_General = data.Tot_General;

      this.bandejaFull = this.generateData(data.ResumenResponses);
      this.dataSourceBackUp = this.bandejaFull;
      this.dataSourceAll = new MatTableDataSource<Bandeja>(this.bandejaFull);
      this.dataSourceAll.paginator = this.paginator;
    }, err => console.log(err) );
  }

  generateData(data: any): Bandeja[] {
    const x: Bandeja[] = [];
    data.forEach((e: any) => {
      x.push({
        Tipo_Pago: e.Tipo_Pago,
        Cuenta_Retiro: e.Cuenta_Retiro,
        Clave: e.Clave,
        Tipo_Retiro: e.Tipo_Retiro,
        Monto_Total: e.Monto_Total,
        Num_Operaciones: e.Num_Operaciones,
        Id_Proceso: e.Id_Proceso,
        Fecha: e.Fecha,
        Status_Op: e.Status_Op,
        Aprobador_1: e.Aprobador_1,
        Aprobador1_Nombre: e.Aprobador1_Nombre,
        Aprobador_2: e.Aprobador_2,
        Aprobador2_Nombre: e.Aprobador2_Nombre,
        Observaciones: e.observaciones,
        Op_Aprobadas: e.Op_Aprobadas,
        Monto_Operaciones_Aplicadas: e.Monto_Operaciones_Aplicadas,
        Op_Rechazadas: e.Op_Rechazadas,
        Monto_Operaciones_Rechazadas: e.Monto_Operaciones_Rechazadas,
        XML_Status_Envio: e.XML_Status_Envio
      });
    });
    return x;
  }

  getValuesCustom(){
    let valor : any; 
    this.getValues(valor,'');
  }

  getValues(event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }, tipo: String) {
    
    //if(this.bF.length === 0 && this.bFcuentaRetiro.length === 0 && this.bFtipoPago.length === 0){
    this.bandejaFull = this.dataSourceBackUp;
    this.total = [];
    //}
    
    if(tipo === 'retiro'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bF.push(event.source.value.Id);
        } else {
          const index: number = this.bF.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bF.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'deposito'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFtipoPago.push(event.source.value.Desc);
        } else {
          const index: number = this.bFtipoPago.indexOf(event.source.value.Desc);
          if (index !== -1) {
              this.bFtipoPago.splice(index, 1);
          }   
        }
      }
    }

    if(tipo === 'cuenta'){
      if (event.isUserInput) {
        if (event.source.selected === true) {
          this.bFcuentaRetiro.push(event.source.value.Id);
        } else {
          const index: number = this.bFcuentaRetiro.indexOf(event.source.value.Id);
          if (index !== -1) {
              this.bFcuentaRetiro.splice(index, 1);
          }   
        }
      }
    }

    this.bandejaFull.forEach((item) => {
      this.bFtipoPago.forEach(e => {
        let cantTipoPago = [];
        if (item.Tipo_Pago === e) {
          cantTipoPago = this.total.filter(
            (interno) => interno.Tipo_Pago == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantTipoPago.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bF.forEach(e => {
        let cantBf = [];
        if (item.Id_Proceso === e) {
          cantBf = this.total.filter(
            (interno) => interno.Id_Proceso == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantBf.length <= 0){
            this.total.push(item);
          }
        } 
      });
      this.bFcuentaRetiro.forEach(e => {
        let cantCuenRet = [];
        if (item.Cuenta_Retiro === e) {
          cantCuenRet = this.total.filter(
            (interno) => interno.Cuenta_Retiro == e && item.Clave === interno.Clave && item.Tipo_Retiro === interno.Tipo_Retiro
          );
          if(cantCuenRet.length <= 0){
            this.total.push(item);
          }
        } 
      });
    });
    
    this.totalFinal = [];
    this.total.forEach(val => this.totalFinal.push(Object.assign({}, val)));


    if(this.total.length > 0 && this.bFtipoPago.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFtipoPago.forEach(e => {
          if (item.Tipo_Pago === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Tipo_Pago).indexOf(item.Tipo_Pago);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bF.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bF.forEach(e => {
          if (item.Id_Proceso === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Id_Proceso).indexOf(item.Id_Proceso);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.total.length > 0 && this.bFcuentaRetiro.length > 0){
      let a = 0;
      this.total.filter((item) => {
        let borrar = true;
        this.bFcuentaRetiro.forEach(e => {
          if (item.Cuenta_Retiro === e) {
            borrar = false;
          } 
        });
        if(borrar){
          const index: number = this.totalFinal.map(e => e.Cuenta_Retiro).indexOf(item.Cuenta_Retiro);
          this.totalFinal.splice(index, 1);
        }
        a++;
      });
    }

    if(this.bF.length === 0 && this.bFcuentaRetiro.length === 0 && this.bFtipoPago.length === 0){
      this.totalFinal = this.dataSourceBackUp;
    }
    
    //Validar monto o clave
    if(this.searchForm.controls.nameFilter.value !== ''){
      this.totalFinal = this.totalFinal.filter((item) => 
        item.Clave === this.searchForm.controls.nameFilter.value || item.Monto_Total.toString() === this.searchForm.controls.nameFilter.value
    );
    }

    this.dataSourceAll = new MatTableDataSource<Bandeja>(this.totalFinal);
    this.bandejaFull = this.totalFinal;
    this.dataSourceAll.paginator = this.paginator;
  }

  exportData():void{

    const x:Response[] = [];
    this.bandejaFull.forEach((e) => {
      x.push({
        fecha: e.Fecha,
        tipoPago: e.Tipo_Pago,
        cuenta: e.Cuenta_Retiro,
        clave: e.Clave,
        tipoRetiro: e.Tipo_Retiro,
        opEnviados: e.Num_Operaciones,
        montoEnviados: e.Monto_Total,
        opAplicados: e.Op_Aprobadas,
        montoAplicados: e.Monto_Operaciones_Aplicadas,
        opNoAplicados: e.Op_Rechazadas,
        montoNoAplicados: e.Monto_Operaciones_Rechazadas
      });
    });
    const ws : XLSX.WorkSheet = XLSX.utils.json_to_sheet(x);
    ws.A1.v = 'Fecha';
    ws.B1.v = 'Tipo de Pago';
    ws.C1.v = 'Cuenta retiro';
    ws.D1.v = 'Clave';
    ws.E1.v = 'Tipo Retiro';
    ws.F1.v = 'No. Op.';
    ws.G1.v = 'Monto';
    ws.H1.v = 'No. Op Aprobadas';
    ws.I1.v = 'Monto Aprobado';
    ws.J1.v = 'No. Op Rechazadas';
    ws.K1.v = 'Monto Rechazado';
    const wb : XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Respuestas');
    XLSX.writeFile(wb, 'Respuestas.xlsx');


    /*var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(this.bandejaFull);
    XLSX.utils.book_append_sheet(wb, ws, 'Respuestas');
    XLSX.writeFile(wb, `${'Respuestas'}.xlsx`);*/
  }

  showDetail(renglon:any): void{
    console.log('Mandando a detalle'+renglon);
    this.router.navigate(['response/detail'], { state: { hello: renglon, status:'B' } });
  }

  invokeCatalog(){
    this.dataBase.obtenerCatalogos()
    .subscribe( data => {
      if(data!== undefined){
        console.log('contenido catalogo ',data);
        this.retiroList = data.value.CatalogoRetiros;
        this.depositosList = data.value.CatalogoTiposPago;
        this.cuentaRetiroList = data.value.CatalogoCuentasRetiro;
      }else{
        console.log('Redirecciona a portal login....');
      }
    }, err => console.log(err) );
  }

  search(status:any): ParamsConsult {
    let fechaActual = this.utils.getActualDate();
    const x: ParamsConsult = {
      fecha: fechaActual,
      estado: status,
      pagina: '1',
      registros: '200',
      idProceso: '',
      claveRetiro: ''
    };
    return x;
  }

}

export interface Response {
  fecha: string;
  tipoPago: string;
  cuenta: string;
  clave: string;
  tipoRetiro: string;
  opEnviados: string;
  montoEnviados: string;
  opAplicados: string;
  montoAplicados: string;
  opNoAplicados: string;
  montoNoAplicados: string;
}
