import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  msg: string = "";
  constructor(
    public dialog: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) {
      this.msg = data.error;
    }
    ngOnInit(): void {
    }

    close(){
      this.dialog.close();
    }

}
