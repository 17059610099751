import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertReviewComponent } from '../modals/alert-review/alert-review.component';
import { Bandeja, Stage } from 'src/app/modules/inbox/components/inbox/inbox.component';
import { MatPaginator } from '@angular/material/paginator';
import { ConsumeService } from 'src/app/services/consume.services';
import { ModifyCountEmmiter } from 'src/app/modules/inbox/components/inbox/modify-count-emmiter';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss']
})
export class DeclineComponent implements OnInit, AfterViewInit {

  retiros = new FormControl('');
  retiroList: string[] = ['Todos', 'Retiro por Aportaciones Voluntarias', 'Retiro por Autorizacion de Pension', 'Retiro por Autorizacion ISSSTE', 'Retiro por Plan Privado ISSSTE', 'Reexpedicion por vigencia por Desempleo', 'Retiro por Autorizacion de Pension', 'Retiro por Autorizacion ISSSTE'];
  depositos = new FormControl('');
  depositosList: string[] = ['Todos', 'SPEI', 'OPR'];
  cuentaRetiro = new FormControl('');
  cuentaRetiroList: string[] = ['Todas', '877677699', '14008004'];

  showComponent: boolean = true;
  tipoStatus:string="";
  status:string="";
  elements : Bandeja[] = [];
  elementsDecline : Bandeja[] = [];
  pagosRechazar : Number = 0;

  @ViewChild(MatPaginator) paginator : MatPaginator;

  constructor(public dialog: MatDialog, private consumeService: ConsumeService, public totalEmmit:ModifyCountEmmiter,
    private utils: UtilsService) { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    console.log(history.state);
    this.elementsDecline = history.state.hello;
    this.tipoStatus = history.state.tipoStatus;
    this.status = history.state.status;
    this.pagosRechazar = this.elementsDecline.length;
    this.dataSource = new MatTableDataSource<Bandeja>(this.elementsDecline);
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = ['tipoPago', 'cuentaRetiro', 'clave', 'tipoRetiro', 'monto', 'noOperaciones', 'motivoRechazo'];
  dataSource = new MatTableDataSource<Bandeja>();

  openAlert() {
    const dialogRef: MatDialogRef<AlertReviewComponent> = this.dialog.open(AlertReviewComponent, {
    });
  }

  confirm() {
    //this.showComponent = !this.showComponent;
    let bandera = false;
    this.elementsDecline.forEach(decline =>{
      if(decline.Observaciones === null ||decline.Observaciones === undefined || decline.Observaciones.trim() === ""){
        bandera = true;
      }
    });
    if(bandera){
      this.openAlert();
    }else{
      console.log('Se aceptan pagos');
      const x: Stage[] = [];
      let fechaActual = this.utils.getActualDate();
      this.elementsDecline.forEach( element =>{
        console.log(element.Observaciones);
        x.push({
          fecha: fechaActual,
          claveRetiro: element.Clave,
          tipoStatus: 'AR',
          status: 'RECH',
          observaciones: element.Observaciones,
          usuario: '',
          usuarioNm: ''
        });
      });
      this.consumeService.actualizarEstatusPago(x).subscribe( data => {
        console.log("updateStage: "+data);
        //TODO: Invocar a refrescar pantalla
        this.showComponent = !this.showComponent;
        this.totalEmmit.ejecutaAccionMenus.emit('Inbox');
      }, err => {
        console.log(err); 
      });
    }
  }

}


  