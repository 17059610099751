<div class="container-component">
    <div class="ds-flex-center-between text-gray margin-bottom-1">
        <div class="font-20">
            <div class="ds-flex-align-center margin-bottom-02" [routerLink]="['..']">
                <img class="margin-right-1" src="../../../../../assets/img/icon/Vector.svg">
                <span class="font-15 breadcrumd">Regresar</span>
            </div>

            <span class="margin-right-04 font-600">{{pagosRechazar}}</span>
            <span class="margin-right-04">Pagos por Rechazar</span>
        </div>
    </div>

    <div>
        <div *ngIf="showComponent" class="text-center font-22 text-blue margin-top-2 margin-bottom-2">¿Desea rechazar los siguientes pagos?</div>

        <div *ngIf="!showComponent" class="ds-flex-column-center margin-top-3">
            <img src="../../../../../assets/img/checked.svg">
            <div class="text-center font-22 text-blue margin-top-2 margin-bottom-2">Los siguientes pagos han sido rechazados:</div>
        </div>

        <table mat-table [dataSource]="dataSource" class="table-content">

            <ng-container matColumnDef="tipoPago">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Tipo de pago </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Tipo_Pago}} </td>
            </ng-container>

            <ng-container matColumnDef="cuentaRetiro">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Cuenta retiro </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Cuenta_Retiro}} </td>
            </ng-container>

            <ng-container matColumnDef="clave">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Clave </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Clave}} </td>
            </ng-container>

            <ng-container matColumnDef="tipoRetiro">
                <th mat-header-cell *matHeaderCellDef> Tipo de retiro </th>
                <td mat-cell *matCellDef="let element"> {{element.Tipo_Retiro}} </td>
            </ng-container>

            <ng-container matColumnDef="monto">
                <th mat-header-cell *matHeaderCellDef> Monto </th>
                <td mat-cell *matCellDef="let element"> {{element.Monto_Total | currency: "MXN":"symbol-narrow"}} </td>
            </ng-container>

            <ng-container matColumnDef="noOperaciones">
                <th mat-header-cell *matHeaderCellDef> No. operaciones </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.Num_Operaciones}} </td>
            </ng-container>

            <ng-container matColumnDef="motivoRechazo">
                <th mat-header-cell *matHeaderCellDef class="w-22"> Motivo de rechazo: </th>
                <td mat-cell *matCellDef="let element">
                    <textarea rows="5" class="w-100 textarea-custom" [(ngModel)]="element.Observaciones">{{element.Observaciones}}</textarea> <!-- (click)="openAlert()" -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons=""></mat-paginator>

        <div *ngIf="" class="ds-flex-center-between alert-custom margin-top-2">
            <div class="ds-flex-align-center">
                <img class="margin-right-2" src="../../../../../assets/img/icon/warning-alert.svg">
                <div>Es obligatorio especificar el motivo de rechazo para cada pago.</div>
            </div>
            <img class="pointer" src="../../../../../assets/img/icon/cancel-alert.svg">
        </div>

        <div *ngIf="showComponent" class="ds-flex-end margin-top-3">
            <button class="button-blue-normal" (click)="confirm()">Confirmar rechazo</button>
        </div>

        <div *ngIf="!showComponent" class="ds-flex-end margin-top-3">
            <button class="button-blue-normal" [routerLink]="['..']">Ir a Revisión</button>
        </div>
    </div>
</div>