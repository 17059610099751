<div class="ds-flex">
    <div class="w-60 ds-flex-column-justify-center">
        <img class="margin-bottom-2" src="../../../../../assets/img/logo-principal-login.svg">
        <span class="font-24 text-gray margin-bottom-4">Host to Host Afore</span>

        <form [formGroup]="formLogin" class="ds-flex-column w-40 margin-bottom-2" method="POST" (ngSubmit)="login()">
            <button mat-raised-button type="submit" class="button-blue-normal" >Iniciar sesión</button> 
        </form>
    </div>
    <div class="img-side ds-flex-align-end">
        <div class="box-gradient"></div>
    </div>
</div>