<mat-dialog-content class="mat-typography content-modal">
    <div class="ds-flex-between">
        <div class="ds-flex-align-center">
            <img class="margin-right-2" src="../../../../assets/img/checked.svg">
            <div>{{msg}}</div>
        </div>
    </div>

    <div style="text-align:right; margin-top:5%">
        <button class="button-blue-normal margin-right-1" (click)="close()">Aceptar</button>
    </div>
</mat-dialog-content>
