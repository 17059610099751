export const PATH = {
    ROOT: '/',
    LOGIN: 'login',
    EMPTY: '',
    HOME: 'home',
    INBOX: 'inbox',
    REVIEW :'review',
    RESPONSE : 'response',
    STATISTICS: 'statistics',
    INBOX_DETAIL : 'detail',
    DECLINE: 'decline',
    RESPONSE_DETAIL : 'detail',
}