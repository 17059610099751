import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-response',
  templateUrl: './notification-response.component.html',
  styleUrls: ['./notification-response.component.scss']
})
export class NotificationResponseComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  close() {
    this.dialog.closeAll();
  }

}
