<mat-dialog-content class="mat-typography">
    <div mat-dialog-title class="font-22 font-400 text-blue text-center">Describe el motivo de notificación de la siguiente respuesta:</div>

    <div mat-dialog-subtitle class="font-18 text-gray-dark text-center">Retiro por Autorizacion ISSSTE</div>

    <table class="w-100 font-16">
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Cuenta Retiro:</td>
            <td class="text-black p-l-05 w-50">877677669</td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Clave:</td>
            <td class="text-black p-l-05 w-50">AV220503</td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">Monto:</td>
            <td class="text-black p-l-05">$124,789.50</td>
        </tr>
        <tr class="h-3">
            <td class="text-gray text-end p-r-05 w-50">No. Operaciones:</td>
            <td class="text-black p-l-05">33</td>
        </tr>
    </table>

    <div class="font-16 text-gray">
        <div class="margin-bottom-05">Motivo de notificación:</div>
        <textarea class="textArea" rows="9"></textarea>
    </div>

</mat-dialog-content>

<mat-dialog-actions class="ds-flex-between margin-top-1">
    <button class="button-white-normal" (click)="close()">
        Cancelar
    </button>

    <button class="button-blue-normal" (click)="close()">
        Guardar Motivo
    </button>
</mat-dialog-actions>
